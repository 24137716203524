<template>
  <div>
    <van-nav-bar left-arrow
                 :title="$t('tbm.查看')"
                 fixed
                 @click-left="$router.go(-1)">
    </van-nav-bar>
    <div style="padding-top:46px;"
         class="applyHolidayView">
      <div>
        <div>
          <p>
            <i class="iconfont icon-yichangshenqing"></i>
          </p>
          <div>
            <p>{{ $t('tbm.异常申请') }}</p>
            <p>{{ $t('tbm.申请时间') }}：{{form.createTime}}</p>
          </div>
        </div>
        <p :style="{color:form.formColor,'font-size':'16px'}">{{form.formStatusName}}</p>
      </div>
      <div>
        <div v-for="(item,index) in formList"
             :key="index">
          <label>{{item.label}}</label>
          <p>{{item.text && form[item.text]}}</p>
        </div>
      </div>

      <div class="overtimeDetailList"
           v-if="flag">
        <div v-for="(item,index) in form.applyUnusualList"
             :key="index"
             v-show="form.applyUnusualList.length > 0">
          <div v-for="(items,key) in dataList"
               :key="key">
            <label>{{items.label}}</label>
            <p v-if="items.text == 'dateDetail'">{{item[items.text].substring(0, 10)}}</p>
            <p v-else-if="items.text == 'pkWorkhourRange'">{{findTextMet(item[items.text],item.workTimes,item)}}</p>
            <p v-else-if="items.text == 'signFlag'">{{findTextMet(item[items.text],signFlagList,item)}}</p>
            <p v-else>{{findTextMet(item[items.text],unusualTypeList,item)}}</p>
          </div>
        </div>
        <div style="background:#eee;display:flex;"
             v-show="form.applyUnusualList.length == 0">
          <img src="@/views/business/images/empty.png"
               alt=""
               style="width:200px;height:150px;margin:20px auto;">
        </div>
      </div>

      <filesList :showFile="true"
                 v-show="filesList.length>0"
                 :filesList="filesList"></filesList>
      <history-panel :pid="form.id"
                     :woType="procConf.woCode"
                     :formNo="form.formNo"></history-panel>

      <div v-if="approvalShow">
        <approval v-if="form"
                  class="approvalFixed"
                  :woCodeValue="procConf.woCode"
                  saveUrl="applyUnusual"
                  :businessInfo="form"
                  :detailPageName="$route.name"
                  :customSubmitBtnName="customSubmitBtnName"
                  :processName="procConf.processInstanceName"
                  :taskId="procConf.taskInstanceId">
        </approval>
      </div>
    </div>
  </div>
</template>
<script>
import filesList from '@/views/business/components/filesList';
import historyPanel from '@/views/business/components/historyPanel';
import approval from '@/components/approval/approval';
export default {
  components: {
    filesList,
    historyPanel,
    approval
  },
  data () {
    return {
      flag: false,
      form: {
        formNo: '',
        attachList: [],
        applyDate: '',
        reason: '',
        applyUnusualList: [],
        pkOrg: '',
        pkUser: '',
        pkDept: '',
        formStatus: '',
        flag: '1',
        formStatusName: '',
        formColor: ''
      },
      formStatus: [
        { label: '暂存', color: '#318B80' },
        { label: '审核中', color: '#FF7405' },
        { label: '作废', color: '#999999' },
        { label: '通过审核', color: '#4378BE' },
      ],
      formList: [
        {
          label: '申请人',
          text: 'userName'
        },
        {
          label: '所在部门',
          text: 'deptName'
        },
        {
          label: '异常明细',
          text: ''
        },
      ],
      dataList: [
        {
          label: '异常日期',
          text: 'dateDetail'
        },
        {
          label: '区间',
          text: 'pkWorkhourRange'
        },
        // {
        //   label: '打卡时间',
        //   text: 'beginTime'
        // },
        {
          label: '上下班标记',
          text: 'signFlag'
        },
        {
          label: '异常类型',
          text: 'unusualType'
        },
      ],
      filesList: [],
      procConf: {
        businessKey: '',
        processInstanceName: '异常申请',
        woCode: 'TbmapplyUnusual', //对应不同的请假类型
        taskInstanceId: '',
        variables: {}, //用于流程条件判断
        taskType: '',
        mappingId: '',
        status: '',
        activityId: '',
      },
      signFlagList: [
        {
          value: '0',
          text: '签到'
        }, {
          value: '1',
          text: '签退'
        }
      ],
      unusualTypeList: [
        { value: '05', text: '设备故障' },
        { value: '06', text: '交通管制' },
        { value: '07', text: '交通故障' },
        { value: '00', text: '忘记打卡' },
      ],
      approvalShow: false,
      customSubmitBtnName: ['同意', '不同意'],
    }
  },
  methods: {
    findTextMet (value, data, items) {
      if (!value || !data || data.length == 0) {
        return value;
      } else {
        return data.find(item => item.value == value).text;
      }
    },
    async backFillEditData () {
      this.$myHttp({
        method: 'POST',
        url: '/hrtbm/applyUnusual/view',
        data: {
          'entity': {
            'id': this.form.id
          }
        }
      }).then(res => {
        let backData = res.data.entity;
        if (backData) {
          let unusualList = backData.applyUnusualList
          if (unusualList.length > 0) {
            Promise.all(unusualList.map(async (item, i) => {
              //耗时操作
              let obj = await this.getRangeNameListForEdit(unusualList[i])
              if (obj.data.ext.length > 0) {
                let workTimes = []
                obj.data.ext.map(data => {
                  let objList = {};
                  objList['value'] = data.id
                  objList['text'] = data.rangeName + '(' + data.startTime + '-' + data.endTime + ')'
                  objList['pkWorkhour'] = data.pkWorkhour
                  workTimes.push(objList)
                })
                backData.applyUnusualList[i]['workTimes'] = workTimes
              } else {
                backData.applyUnusualList[i].pkWorkhourRange = ''
              }
              this.flag = false;
              this.flag = true;
            }));
          }
        }
        this.form = backData;
        if (this.$route.params.obj) {
          let rowIndex = this.$route.params.obj.rowIndex;
          if (rowIndex !== -1) {
            rowIndex = parseInt(rowIndex);
            this.form.applyUnusualList[rowIndex] = this.$route.params.obj.data;
          } else {
            this.form.applyUnusualList.push(this.$route.params.obj.data)
          }
        }
        this.deptName = backData.deptName
        this.userName = backData.userName;
        this.procConf.businessKey = this.form.id;
        this.deptAndPost = parseInt(res.data.ext.deptAndPost);
        this.form.formStatusName = this.formStatus[backData.formStatus].label;
        this.form.formColor = this.formStatus[backData.formStatus].color;

        let deptName = JSON.parse(localStorage.getItem('userInfo')).deptName;
        let userName = JSON.parse(localStorage.getItem('userInfo')).cname;
        let officeoutReason = this.form.reason;
        if (officeoutReason.length > 5) {
          officeoutReason = officeoutReason.substring(0., 5) + '……'
        }
        this.procConf.processInstanceName = this.procConf.processInstanceName + '-' + deptName + ' ' + userName + '-' + officeoutReason + '-' + this.form.totalDays + '天'
        if (this.$route.query.type) {
          this.approvalShow = true;
        }
      });
    },
    // 编辑 根据日期获取班时区间 构建option
    async getRangeNameListForEdit (row) {
      return this.$myHttp({
        method: 'POST',
        url: '/hrtbm/applyUnusual/getRangeNameList',
        params: {
          'dateDetail': row.dateDetail.substr(0, 10)
        }
      })
    },
    getFilesList () {
      this.$myHttp({
        method: 'post',
        url: '/microarch/sys/sysAttchement/list',
        data: {
          'entity': {
            'bizId': this.form.id
          }
        }
      }).then(res => {
        let backData = res.data;
        if (backData) {
          this.filesList = backData
        }
      });
    },
  },
  created () {
    this.form.id = this.$route.query.businessKey;
    if (this.$route.query['row']) {
      let row = JSON.parse(this.$route.query['row']);
      if (row && row['pid']) {
        this.form.id = this.EhrUtils.isEmpty(this.form.id) ? row['businessKey'] : this.form.id;
        this.procConf.taskInstanceId = row['id'];
        this.procConf.taskType = row['taskType'];
        this.procConf.status = row['status'];
        this.procConf.activityId = row['activityId'];
        this.procConf.activityCode = row['activityCode'];// 节点编码
      }
    }
    this.backFillEditData();
    this.getFilesList();
  }
}
</script>
<style scoped>
.overtimeDetailList {
  padding: 10px 10px 0 10px;
}
.overtimeDetailList > div {
  background: #fff;
  border-radius: 15px;
  padding: 10px;
  margin-bottom: 10px;
}
.overtimeDetailList > div > div {
  display: flex;
  margin: 10px 0;
}
.overtimeDetailList > div label {
  margin-right: 20px;
  text-align: right;
  color: rgb(102, 102, 102);
  font-size: 16px;
  width: 80px;
}
</style>

<style>
.applyHolidayView > div:nth-child(1) {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px 10px 10px;
  background: #fff;
  margin-bottom: 10px;
}
.applyHolidayView > div:nth-child(1) > div {
  display: flex;
  align-items: center;
}
.applyHolidayView > div:nth-child(1) > div > p {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(254, 156, 110, 1);
  margin-right: 10px;
}
.applyHolidayView > div:nth-child(1) > div > p i {
  width: 27px;
  height: 27px;
  font-size: 27px;
  color: #fff;
}
.applyHolidayView > div:nth-child(1) > div > div {
  font-size: 18px;
  color: rgb(51, 51, 51);
}
.applyHolidayView > div:nth-child(1) > div > div p:nth-child(2) {
  font-size: 14px;
  color: rgb(102, 102, 102);
  margin-top: 5px;
}
.applyHolidayView > div:nth-child(2) {
  background: #fff;
  padding: 0 10px 10px;
}
.applyHolidayView > div:nth-child(2) > div {
  height: 44px;
  display: flex;
  align-items: center;
}
.applyHolidayView > div:nth-child(2) > div label {
  margin-right: 20px;
  text-align: right;
  color: rgb(102, 102, 102);
  font-size: 16px;
  width: 80px;
}
</style>
